import './Video.css';
import ReactPlayer from 'react-player';
import screenfull from 'screenfull';
import React, { forwardRef, useState } from "react";

function Video({currentTime, setCurrentTime, playerRef, play, url}) {

    return (
        <div className='playerDiv'>
            <ReactPlayer width='100%' height='100%'
            url= {url}
            playing={play}
            controls
            onProgress={progress => setCurrentTime(progress.playedSeconds)}
            currenttime={currentTime}
            ref={playerRef}
            />
        </div>
    )
}

export default forwardRef(Video);