import React from "react";
import { Link } from "react-router-dom";
import AppBar from '@mui/material/AppBar';

// MUI imports : 
import { Button, Toolbar, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    success:{
      main: "#FFFFFF"
    }  
  }
})

function Navbar() {
  return (
    <ThemeProvider theme={theme}>
      <AppBar position = 'relative'>
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap>
            Paris Digital Lab
          </Typography>
          <nav>
            <ul>
                <Link to="/">
                    <Button variant="outlined" color="success" disableElevation={true} fullWidth={true}>
                        Go to home
                    </Button>
                </Link>
            </ul>
          </nav>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
}

export default Navbar;