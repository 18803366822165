import React from "react";
import { Routes, Route } from 'react-router-dom';

// Components import :
import Home from '../pages/Home';
import PageTemplate from "../pages/PageTemplate";

const Main = () => {
    return (
        <Routes> {/* The Switch decides which component to show based on the current URL.*/}
            <Route path="/" element={ <Home /> }></Route>
            <Route path="/:number" element={ <PageTemplate /> }></Route>
        </Routes>
    )
}

export default Main;