import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";

// MUI imports :
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Chip, CssBaseline, Grid, Paper, Stack, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

// Components import :
import Video from "../components/videopage/Video";
import MCQDialog from "../components/videopage/MCQDialog";
import ExplainButton from "../components/videopage/ExplainButton";
import RightsideAccordions from "../components/videopage/RightsideAccordions";

function PageTemplate(){

    // Imports of json :
    const jsonFiles = require.context('../data', false, /\.json$/);
    const jsonData = [];

    jsonFiles.keys().forEach((fileName) => {
        const fileData = jsonFiles(fileName);
        jsonData.push(fileData);
    });
    
    // Take the number in the URL linked to this page :
    const number = useParams();

    const selectedJson = jsonData[number.number];

    const theme = createTheme();

    // Hightlighting & Clicking text constants/functions :
    const [currentTime, setCurrentTime] = useState(0);
    const playerRef = useRef(null);

    function handleSentenceClick(startTime){
        setCurrentTime(startTime);
        playerRef.current.seekTo(startTime, "seconds");
    };

    // Pause video function : 
    const [play, setPlay] = useState(true);

    function stop_video() {
        setPlay(false);
    }

    // Click download pdf for transcripts/slides :
    const handleDownloadPDFClick = (number) => {
        if (number.number === "0") {
            const fileUrl = "/RL1_t_s.pdf"
            window.open(fileUrl, "_blank");
        } else if (number.number === "1") {
            const fileUrl = "/RL2_t_s.pdf"
            window.open(fileUrl, "_blank")
        } else {
            const fileUrl = "/socio_t_s.pdf"
            window.open(fileUrl, "_blank")
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <Grid container component='main' sx={{height: '100vh'}}>
                <CssBaseline />
                <Grid item xs={12} sm={8} md={8}>

                    {/* Accordion for metadata of video */}
                    <Accordion>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        >
                            <Typography variant="h5">
                                {selectedJson["metadata"]["title"]}
                            </Typography>
                            <Box marginLeft={4}>
                                <Button variant="contained" startIcon={<FileDownloadIcon />} onClick={() => handleDownloadPDFClick(number)}>
                                    Combined retranscription and slides (.pdf)
                                </Button>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack direction="row" spacing={2} alignItems="center" justifyContent="center" useFlexGap flexWrap="wrap">
                                {selectedJson["metadata"]["topics"].map((topic, index) =>
                                    <Chip id={index} label={topic} color="info" key={topic}/>
                                )}
                            </Stack>
                        </AccordionDetails>
                    </Accordion>

                    <Box sx={{display: 'flex', flexDirection: 'column', color: 'gray'}}>
                        <Video currentTime={currentTime} setCurrentTime={setCurrentTime} play={play} playerRef={playerRef} url={selectedJson["metadata"]["video"]}/>
                    </Box>

                    {/* Two different buttons : one for rephrasing, and the other for the MCQ */}

                    <Grid container>
                        <Grid item xs={12} sm={6} md={6}>
                            <ExplainButton currentTime={currentTime} jsonFilename={jsonFiles.keys()[number.number]} playerRef={playerRef} stop_video={stop_video}/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <MCQDialog playerRef={playerRef} mcq_json={selectedJson["MCQ"]} transcript_json={selectedJson["transcript"]}/>
                        </Grid>
                    </Grid>

                </Grid>

                {/* Rightside Bar with Description and Transcript */}
                <Grid item xs={12} sm={4} md={4} component={Paper} elevation={6} square>
                    <Box sx={{my: 2, mx: 4, display: 'flex', flexDirection: 'column', overflow: "hidden", overflowY: "scroll", height: "100vh"}}>
                        <RightsideAccordions selectedJson={selectedJson} currentTime={currentTime} onSentenceClick={handleSentenceClick}/>
                    </Box>
                </Grid>
            </Grid>
        </ThemeProvider>
    )
}

export default PageTemplate;