import React, { useState } from "react";
import axios from "axios";

// MUI imports :
import { Button } from "@mui/material";
import { Lightbulb } from "@mui/icons-material";

function ExplainButton({ currentTime, jsonFilename, stop_video }) {

    const [result, setResult] = useState(null);

    const filePath = "/back/data" + jsonFilename.slice(1);

    function handleClickExplain(endTime) {
        
        // We take the 10 last seconds
        const startTime = endTime - 10;
        // The following route should be changed if you work locally
        const prefix = "https://aristote.centralesupelec.fr"

        axios.post(prefix + "/explanation?start_time=" + startTime.toString() + "&end_time=" + endTime.toString() + "&file_path=" + filePath)  
        .then(response => {
            setResult(response.data.result)
        })
        .catch(error => {
            console.error(error);
        })

        stop_video();
    };

    return (
        <div>
            <Button variant="contained" startIcon={<Lightbulb />} onClick={ () => handleClickExplain(currentTime)}>
                Please explain the last 10 seconds !
            </Button>
            { result && <div> {result} </div> }
        </div>
    );
};

export default ExplainButton;