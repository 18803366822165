import React, { useState } from 'react';

// MUI imports :
import { Button, FormControlLabel, Grid, List, ListItem, ListItemText, Radio, RadioGroup, Typography } from '@mui/material';
import { CheckCircleOutline, HighlightOff } from '@mui/icons-material';


function MCQ({ playerRef, mcq_json, transcript_json, handleClose }) {

    const [selectedAnswers, setSelectedAnswers] = useState({});
    const [showResult, setShowResult] = useState(false);
    const [score, setScore] = useState(0);

    const handleAnswerSelect = (question, answer) => {
        setSelectedAnswers({ ...selectedAnswers, [question]: answer})
    };

    const handleMCQSubmit = () => {
        let currentScore = 0;
        mcq_json.forEach((question) => {
            if (selectedAnswers[question.question] === question.answer) {
                currentScore++;
            }
        })
        setScore(currentScore);
        setShowResult(true);
    };

    // Handling explaining of MCQ answers :

    function formatTimestamp(timestamp) {
        const minutes = Math.floor(timestamp / 60);
        const seconds = Math.floor(timestamp % 60);
      
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = seconds.toString().padStart(2, '0');
      
        return `${formattedMinutes}:${formattedSeconds}`;
    }
      
    function getStartTimestamp(transcript, textInput) {
        const matchingSentence = transcript.sentences.find(
            sentence => sentence.text.includes(textInput)
        ); 

        if (matchingSentence) {
            const timestamps = [matchingSentence.start, formatTimestamp(matchingSentence.start)];
            return timestamps;
        }

        return null;
    }

    const [hoveredText, setHoveredText] = useState('');
    const [startTimestamp, setStartTimestamp] = useState([0, '']);
    const [rightAnswer, setRightAnswer] = useState("");

    const handleHover = (transcript, textInput, answer) => {
        const timestamp = getStartTimestamp(transcript, textInput);

        setHoveredText(textInput);
        setStartTimestamp(timestamp);
        setRightAnswer(answer);
    }

    const handleClickExplain = (startTimestamp) => {
        playerRef.current.seekTo(startTimestamp, "seconds");
        handleClose();
    };

    if (showResult) {
        return (
            <div>
                <Typography variant="h4">Result</Typography>
                <Typography variant="h6">Your score: {score} out of {mcq_json.length}</Typography>

                <List>
                    {mcq_json.map((question) => (
                        <ListItem
                        key={question.question}
                        style={{ 
                            border: selectedAnswers[question.question] === question.answer ? '2px dashed green' : '2px dashed red',
                            padding: '1rem',
                            margin: '0 0 10px 0'
                        }}
                        >
                            <Grid container component='main' direction="column" spacing={1}>
                                <Grid item xs={12} md={1} lg={1}>
                                    {selectedAnswers[question.question] === question.answer ? (
                                        <CheckCircleOutline style={{ color: 'green' }} />
                                    ) : (
                                        <HighlightOff style={{ color: 'red' }} />
                                    )}
                                </Grid>
                                <Grid item xs={12} md={6} lg={6} direction={"row"}>
                                    <Grid container>
                                        <Grid item xs={12} md={11} lg={11}>
                                            <ListItemText>  
                                                <Typography sx={{ fontWeight: "bold" }}>
                                                    {question.question}
                                                </Typography>
                                            </ListItemText>
                                        </Grid>
                                        <Grid item xs={12} md={1} lg={1}>
                                            <Button size="small"
                                            onMouseEnter={() => handleHover(transcript_json, question.explanation, question.answer)}
                                            onMouseLeave={() => {
                                                setHoveredText(""); 
                                                setStartTimestamp([0, ""]);
                                                setRightAnswer("");
                                            }}
                                            onClick={() => handleClickExplain(startTimestamp[0])}
                                            >
                                                Why ?
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={5} lg={5}>
                                    <RadioGroup
                                    aria-label={question.question}
                                    name={question.question}
                                    value={selectedAnswers[question.question] || ''}
                                    >
                                    {Object.entries(question.choices).map(([key, value]) => (
                                        <FormControlLabel
                                        key={key}
                                        value={key}
                                        control={<Radio />}
                                        label={value}
                                        />
                                    ))}
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                        </ListItem>
                    ))}
                    { hoveredText && (
                        <div
                        style={{
                            position: 'fixed',
                            left: '50%',
                            top: '50%',
                            transform: 'translate(-50%, -50%)',
                            backgroundColor: 'white',
                            padding: '5px',
                            boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.3)',
                        }}
                        >
                            <p>The right answer is: {rightAnswer}</p>
                            <p>The explanation begins with: {hoveredText}</p>
                            <p>When is the explanation ? : {startTimestamp[1]}</p>
                        </div>
                    )}
                </List>
            </div>
        );
    }

    return (
        <div>
            <List >
                {mcq_json.map((question) => (
                <ListItem key={question.question}>
                    <Grid container component='main' display="flex" direction="column" spacing={1}>
                        <Grid item>
                            <ListItemText>
                                <Typography sx={{ fontWeight: "bold" }}>
                                    {question.question}
                                </Typography>
                            </ListItemText>
                        </Grid>
                        <Grid item>
                            <RadioGroup
                            aria-label={question.question}
                            name={question.question}
                            value={selectedAnswers[question.question] || ''}
                            onChange={(event) => handleAnswerSelect(question.question, event.target.value)}
                            >
                            {Object.entries(question.choices).map(([key, value]) => (
                                <FormControlLabel
                                key={key}
                                value={key}
                                control={<Radio />}
                                label={value}
                                />
                            ))}
                            </RadioGroup>
                        </Grid>
                    </Grid>
                </ListItem>
                ))}
            </List>
            <Button
                variant="contained"
                disabled={Object.keys(selectedAnswers).length !== mcq_json.length}
                onClick={handleMCQSubmit}
            >
                Submit
            </Button>
        </div>
    )
}

export default MCQ;