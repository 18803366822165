import React, { useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

function ThemeSelect() {
    const [selectedTheme, setSelectedTheme] = useState("");

    const handleThemeChange = (event) => {
        const theme = event.target.value;
        setSelectedTheme(theme);
    };

    return (
        <FormControl sx={{ m: 1, minWidth: 250 }}>
            <InputLabel>Select Theme</InputLabel>
            <Select
                labelId="theme-label"
                id="theme-select"
                value={selectedTheme}
                onChange={handleThemeChange}
                label="Which theme do you want ?"
                autoWidth
            >
                <MenuItem value="">Any Theme</MenuItem>
                <MenuItem value="Mathématiques">Mathématiques</MenuItem>
                <MenuItem value="Physique">Physique</MenuItem>
                <MenuItem value="Biologie">Biologie</MenuItem>
                <MenuItem value="Chimie">Chimie</MenuItem>
                <MenuItem value="Astronomie">Astronomie</MenuItem>
                <MenuItem value="Cosmologie">Cosmologie</MenuItem>
                <MenuItem value="Sciences de la Terre">Sciences de la Terre</MenuItem>
                <MenuItem value="Langues vivantes">Langues vivantes</MenuItem>
                <MenuItem value="Musique">Musique</MenuItem>
                <MenuItem value="Peinture">Peinture</MenuItem>
                <MenuItem value="Littérature">Littérature</MenuItem>
                <MenuItem value="Cinéma">Cinéma</MenuItem>
                <MenuItem value="Histoire">Histoire</MenuItem>
                <MenuItem value="Géographie">Géographie</MenuItem>
                <MenuItem value="Géopolitique">Géopolitique</MenuItem>
                <MenuItem value="Sociologie">Sociologie</MenuItem>
                <MenuItem value="Psychologie">Psychologie</MenuItem>
                <MenuItem value="Economie">Economie</MenuItem>
                <MenuItem value="Sciences politiques">Sciences politiques</MenuItem>
                <MenuItem value="Pédagogie">Pédagogie</MenuItem>
                <MenuItem value="Religion">Religion</MenuItem>
                <MenuItem value="Philosophie">Philosophie</MenuItem>
                <MenuItem value="Programmation">Programmation</MenuItem>
                <MenuItem value="Intelligence Artificielle">Intelligence Artificielle</MenuItem>
                <MenuItem value="Cybersécurité">Cybersécurité</MenuItem>
                <MenuItem value="Environnement">Environnement</MenuItem>
            </Select>
        </FormControl>
    );
};

export default ThemeSelect;