import React, { useState } from 'react';
import { Link } from "react-router-dom";

// MUI imports :
import { Button, Card, CardActions, CardContent, CardMedia, Chip, Grid, Tooltip, Typography } from '@mui/material';

function MyCard({ photoUrl, title, description, tags, number }) {
    const [showFullDescription, setShowFullDescription] = useState(false);

    const toggleDescription = () => {
        setShowFullDescription(!showFullDescription);
    }

    return (
        <Card
        sx={{ 
        height: '100%', display: 'flex', flexDirection: 'column' ,
        borderRadius: '16px', // make the card more round in the angles
        maxWidth: 600
        }}
        >   
            <Link to={`/${number}`}>
                <CardMedia
                    component="img"
                    sx={{
                    // 16:9
                    pt: '10.25%'
                    }}
                    image={photoUrl}
                    alt="random"
                />
            </Link>
            <CardContent sx={{ flexGrow: 1 }}>
                <Typography gutterBottom variant="h8" component="h4" textAlign="left" sx={{ lineHeight: 1.2 }}>
                    {title}
                </Typography>
                <Typography variant="body2" color="text.secondary" textAlign="left">
                    {showFullDescription ? description : `${description.slice(0, 50)}...`}
                    <Button size="small" onClick={toggleDescription} style={{ textTransform: 'none' }}>
                        {showFullDescription ? 'Show less' : 'Show more'}
                    </Button>
                </Typography>
            </CardContent>
            <CardActions>
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <Tooltip title={tags["Domaine"]}>
                            <Chip id={tags["Domaine"]} label={tags["Domaine"]} color="info" key={tags["Domaine"]}
                            sx = {{ overflow: "hidden", textOverflow: "ellipsis", maxWidth: 80}}
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <Tooltip title={tags["Niveau"]}>
                            <Chip id={tags["Niveau"]} label={tags["Niveau"]} color="info" key={tags["Niveau"]}
                            sx = {{ overflow: "hidden", textOverflow: "ellipsis", maxWidth: 80}}
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <Tooltip title={tags["Format"]}>
                            <Chip id={tags["Format"]} label={tags["Format"]} color="info" key={tags["Format"]}
                            sx = {{ overflow: "hidden", textOverflow: "ellipsis", maxWidth: 80}}
                            />
                        </Tooltip>
                    </Grid>
                </Grid>
            </CardActions>
        </Card>
    )
}

export default MyCard;