import { Button, Dialog, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import * as React from 'react';
import MCQ from './MCQ';

export default function MCQDialog({playerRef, mcq_json, transcript_json}) {

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button variant="contained" onClick={handleClickOpen}>
                Test yourself !
            </Button>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
                <DialogTitle> MCQ </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <MCQ playerRef={playerRef} mcq_json={mcq_json} transcript_json={transcript_json} handleClose={handleClose}/>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </div>
    )
}