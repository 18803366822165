import React, { useEffect, useState, useRef } from 'react';
import './VideoTranscript.css';


// MUI Imports :
import { Grid, Typography } from "@mui/material"

function VideoTranscript({ transcript_sentences, currentTime, onSentenceClick, searchTerm }) {

    // Autoscroll :

    const transcriptRef = useRef(null);

    const [highlightedIndex, setHighlightedIndex] = useState(0);

    useEffect(() => {
        // Find the index of the sentence corresponding to the current time
        const currentIndex = transcript_sentences.findIndex(
          (sentence) => currentTime >= sentence.start && currentTime <= sentence.end
        );
      
        // Update the highlightedIndex state
        if (currentIndex !== -1) {
          setHighlightedIndex(currentIndex);
        }
    }, [currentTime, transcript_sentences]);

    const highlightedSentenceRef = useRef(null);

    useEffect(() => {
        if (highlightedSentenceRef.current) {
            highlightedSentenceRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            });
        }
    }, [highlightedIndex]);
      

    // When clicking on timestamp : jumps to the selected timestamp in the video :
    function handleSentenceClick(sentence) {
        onSentenceClick(sentence.start);
    }

    // Hovering timestamp handling :
    const [hoveredIndex, setHoveredIndex] = useState(null);

    const handleMouseEnter = (index) => {
        setHoveredIndex(index);
    };

    const handleMouseLeave = () => {
        setHoveredIndex(null);
    };

    function formatTimestamp(timestamp) {
        const minutes = Math.floor(timestamp / 60);
        const seconds = Math.floor(timestamp % 60);
        
        if (minutes < 60) {
            const formattedMinutes = minutes.toString().padStart(2, '0');
            const formattedSeconds = seconds.toString().padStart(2, '0');
        
            return `${formattedMinutes}:${formattedSeconds}`;
        } else {
            const hours = Math.floor(minutes / 60);
            const minutes_hours = Math.floor(minutes % 60);

            const formattedHours = hours.toString().padStart(2, '0');
            const formattedMinutes_Hours = minutes_hours.toString().padStart(2, '0');
            const formattedSeconds = seconds.toString().padStart(2, '0');

            return `${formattedHours}:${formattedMinutes_Hours}:${formattedSeconds}`
        }
    }

    // Search function :

    function searchScrollToSentence(sentenceText, sentenceIndex, searchTerm) {

        if (searchTerm !== "") {
            const isMatch = sentenceText.includes(searchTerm);
            return isMatch;

        } else {
            return false;
        }
    }

    return (
    <div ref={transcriptRef} style={{ textAlign: "left", overflowY: 'scroll' }}>
        {transcript_sentences.map((sentence, index) => (
        <Grid container key={index}>
            <Grid item xs={12} md={2} lg={2}>
            <Typography variant="overline"
            key={index}
            onClick={() => handleSentenceClick(sentence)}
            className={index === hoveredIndex ? "hovered-timestamp" : "timestamp"}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
            >
                {formatTimestamp(sentence.start)}
            </Typography>
            </Grid>
            <Grid item xs={12} md={10} lg={10}>
                <Typography 
                key={index}
                className={index === highlightedIndex ? 'active-sentence' : ''}
                ref={index === highlightedIndex ? highlightedSentenceRef : null}>
                    <Typography className={searchScrollToSentence(sentence.text, index, searchTerm) ? "searched" : ""}>
                        {sentence.text}
                    </Typography>
                </Typography>
            </Grid>
        </Grid>
        ))}
    </div>
    );
}

export default VideoTranscript;