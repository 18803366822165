import React from 'react';

// MUI imports : 
import { Box, CssBaseline, Container, Grid, TextField, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// Components imports :
import MyCard from '../components/homepage/MyCard';
import ThemeSelect from '../components/homepage/ThemeSelect';
import DifficultySelect from '../components/homepage/DifficultySelect';
import FormatSelect from '../components/homepage/FormatSelect';

const theme = createTheme();

function Home(){

    // Imports of JSONs :
    const jsonFiles = require.context('../data', false, /\.json$/);
    const jsonData = [];

    jsonFiles.keys().forEach((fileName) => {
        const fileData = jsonFiles(fileName);
        jsonData.push(fileData);
    });

    console.log(jsonData);

    const numbers = Array.from({ length: jsonData.length }, (_, index) => index);

    // Handling of thumbnails videos : 
    function getThumbnail(number) {
        if (jsonData[number]["metadata"]["linkpicture"]) {
            return jsonData[number]["metadata"]["linkpicture"];
        } else {
            return "templateimage.jpg";
        }
    }

    return (
        <ThemeProvider theme ={theme}>
            <CssBaseline />
            <main>
                <Box
                sx={{
                    bgcolor: 'background.paper',
                    pt: 8,
                    pb: 6,
                }}
                >
                    <Container maxWidth="md">
                        <Typography
                        component="h1"
                        variant="h2"
                        align="center"
                        color="text.primary"    
                        gutterBottom
                        >
                            Aristote.education
                        </Typography>
                        <Typography variant="h4" align="center" color="text.secondary" paragraph>
                            Bringing videos to life ! 
                        </Typography>
                        <Typography variant="h6" align="center" color="text.secondary" paragraph>
                            Project from Paris Digital Lab, BigBlueButton and FUN
                        </Typography>
                    </Container>
                </Box>
                <Container sx={{ py: 2 }} maxWidth="lg">
                    <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    >
                        <TextField label="Search by categories" sx={{ width: "600px"}}/>
                    </Box>
                    <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    >
                        <ThemeSelect />
                        <DifficultySelect />
                        <FormatSelect />
                    </Box>
                    <Grid container spacing={4}>
                        {numbers.map((number) => (
                        <Grid item key={number} xs={12} sm={6} md={3} lg={3}>
                            <MyCard photoUrl={getThumbnail(number)} title={jsonData[`${number}`]["metadata"]["title"]} description={jsonData[`${number}`]["metadata"]["desc"]} tags={jsonData[`${number}`]["metadata"]["tags"]} number={number}/>
                        </Grid>
                        ))}
                    </Grid>
            </Container>
            </main>
        </ThemeProvider>
    );
}

export default Home;