import React, { useState } from "react";

// Import MUI Components :
import { Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";

import { TextField } from "@mui/material";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Import components :
import VideoTranscript from "./VideoTranscript";

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props}/>
    ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
    '&.Mui-expanded': {
        transition: 'height 200ms', // Adjust the duration to your desired value
    }
    }));


const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
    ))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
    justifyContent: "flex-start"
}));

function RightsideAccordions({ selectedJson, currentTime, onSentenceClick}) {
    
    const [expanded, setExpanded] = useState("description");

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }

    // Search function :
    
    const [searchTerm, setSearchTerm] = useState("");

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value)
    };

    return (
        <div>
            <Accordion expanded={expanded === "description"} onChange={handleChange("description")}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="description-content"
                id="description-header"
                >
                    <Typography variant="h7">
                        In this video
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography fontSize={16} style={{textAlign: "left"}}>
                        {selectedJson["metadata"]["desc"]}
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === "transcript"} onChange={handleChange("transcript")}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="transcript-content"
                id="transcript-header"
                >
                    <Typography variant="h7">
                        Retranscription of the audio
                    </Typography>
                </AccordionSummary>
                <AccordionDetails position={"relative"}>
                    <TextField 
                    label={"Search in text"} 
                    value={searchTerm}
                    onChange={handleSearchChange}
                    fullWidth
                    style={{
                        position: 'sticky',
                        top: 0,
                        backgroundColor: 'white',
                        zIndex: 1
                    }}
                    />
                    <Typography fontSize={16} marginTop={2}>
                        <VideoTranscript transcript_sentences={selectedJson["transcript"].sentences} currentTime={currentTime} onSentenceClick={onSentenceClick} searchTerm={searchTerm}/>
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default RightsideAccordions;