import React, { useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

function FormatSelect() {
    const [selectedFormat, setSelectedFormat] = useState("");

    const handleFormatChange = (event) => {
        const format = event.target.value;
        setSelectedFormat(format);
    };

    return (
        <FormControl sx={{ m: 1, minWidth: 250 }}>
            <InputLabel>Select Format</InputLabel>
            <Select
                labelId="format-label"
                id="format-select"
                value={selectedFormat}
                onChange={handleFormatChange}
                label="Which format do you want ?"
                autoWidth
            >
                <MenuItem value="">Any Format</MenuItem>
                <MenuItem value="Cours">Cours</MenuItem>
                <MenuItem value="Interview">Interview</MenuItem>
                <MenuItem value="Conférence">Conférence</MenuItem>
                <MenuItem value="Atelier">Atelier</MenuItem>
                <MenuItem value="Tutoriel">Tutoriel</MenuItem>
            </Select>
        </FormControl>
    );
};

export default FormatSelect;