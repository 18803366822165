import React, { useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

function DifficultySelect() {
    const [selectedDifficulty, setSelectedDifficulty] = useState("");

    const handleDifficultyChange = (event) => {
        const difficulty = event.target.value;
        setSelectedDifficulty(difficulty);
    };

    return (
        <FormControl sx={{ m: 1, minWidth: 250 }}>
            <InputLabel>Select Difficulty</InputLabel>
            <Select
                labelId="difficulty-label"
                id="difficulty-select"
                value={selectedDifficulty}
                onChange={handleDifficultyChange}
                label="Which difficulty do you want ?"
                autoWidth
            >
                <MenuItem value="">Any Format</MenuItem>
                <MenuItem value="Facile">Facile</MenuItem>
                <MenuItem value="Intermédiaire">Intermédiaire</MenuItem>
                <MenuItem value="Difficile">Difficile</MenuItem>
            </Select>
        </FormControl>
    );
};

export default DifficultySelect;